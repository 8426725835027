import React from 'react';
import './App.css';
import Footer from './components/lib/common/Footer';
import { AppProvider, useAppContext } from './AppContext';


import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { ModalProvider } from './components/lib/modal/ModalContext';
import MainPage from './components/booking_hospital/MainPage';
import RequireAuth from './components/lib/common/RequireAuth';
import { AuthProvider } from './components/lib/provider/AuthContext';
import AdminLogin from './components/booking_hospital/AdminLogin';
import Admin from './components/booking_hospital/Admin';
import RequireAdminAuth from './components/lib/common/RequireAdminAuth';
import AutoRedirect from './components/lib/module/AutoRedirect';
import NamePhone from './components/booking_hospital/NamePhoneForm';
import CheckQueryParameter from './components/lib/module/CheckQueyParameter';

function App() {

  /*  @Query('business') business: string,
   @Query('media') media: string,
   @Query('category') category: string,
   @Query('campaign_name') campaign_name: string, */

  /*   NamePhoneForm */
  return (
  
      <AppProvider>
        <ModalProvider>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path='/booking/:storeid' element={(<RequireAuth redirectTo='/'><MainPage key='main'></MainPage></RequireAuth>)} ></Route>
                <Route path='/admin-login' element={(<AdminLogin></AdminLogin>)} ></Route>
                <Route path='/admin' element={(<RequireAdminAuth redirectUrl='/admin-login'><Admin></Admin></RequireAdminAuth>)} ></Route>
                {/*               <Route path='/admin/:store' element={(<RequireAdminAuth redirectUrl='/admin-login'><Admin></Admin></RequireAdminAuth> )} ></Route> */}
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ModalProvider>
      </AppProvider>
 
  );
}

export default App;
